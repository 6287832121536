import { LocalStorageKeys } from "../constants/localStorageKeys";
import Config from "../config/config";

function clearAuth() {
  window.localStorage.removeItem(LocalStorageKeys.IdToken);
  window.localStorage.removeItem(LocalStorageKeys.RefreshToken);
}

export function login() {
  clearAuth();

  // Save current route to store, so that we can get back there after Cogito UI sends us back
  window.localStorage.setItem(LocalStorageKeys.PathBeforeRedirect, window.location.pathname);

  window.location.href = `${Config.cognitoUserPoolDomain}` +
    `/login?response_type=code&client_id=${Config.cognitoClientId}` +
    `&redirect_uri=${Config.cognito.redirectBaseUrl}/oauth-sign-in`;
}

export function logout() {
  clearAuth();
  window.location.href = `${Config.cognitoUserPoolDomain}` +
    `/logout?client_id=${Config.cognitoClientId}` +
    `&response_type=code` +
    `&redirect_uri=${Config.cognito.redirectBaseUrl}/oauth-sign-in`;
}
