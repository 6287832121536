import { AuthorizationCodeResponse } from "../../types/cognito";
import axios, { AxiosResponse } from "axios";
import Config from "../../config/config";
import qs from "qs";
import { call, put, takeLatest } from "redux-saga/effects";
import { AppActionTypes } from "./appTypes";
import { FetchToken, FetchTokenFailed, FetchTokenSuccess } from "./appActions";
import { LocalStorageKeys } from "../../constants/localStorageKeys";

function* fetchOauthToken(action: FetchToken) {
  try {
    const response: AxiosResponse<AuthorizationCodeResponse> = yield call(
      axios.post,
      `${Config.cognitoUserPoolDomain}/oauth2/token`,
      qs.stringify({
        grant_type: 'authorization_code',
        client_id: Config.cognitoClientId,
        redirect_uri: `${Config.cognito.redirectBaseUrl}/oauth-sign-in`,
        code: action.payload,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

    localStorage.setItem(LocalStorageKeys.IdToken, response.data.id_token);
    localStorage.setItem(LocalStorageKeys.RefreshToken, response.data.refresh_token);

    yield put(new FetchTokenSuccess(response.data));
  } catch (e) {
    console.error(e)
    yield put(new FetchTokenFailed());
  }
}

export default function* appSagasWatcher() {
  yield takeLatest(AppActionTypes.FETCH_TOKEN, fetchOauthToken);
}
