import { getEnvironment } from '../utils/environment';
import { Environment } from '../types/environment';

interface EnvVars {
  cognitoClientId?: string;
  cognitoUserPoolDomain?: string;
}

interface Configuration extends EnvVars {
  env: Environment;
  rootRoute: string;
  baseUrl: string;
  tvShowServicePath: string;
  productPath: string;
  saleCountPath: string;
  stockPath: string;
  reportingService?: string;
  productOnAirPath: string;
  assetsUrl: string;
  cognito: {
    redirectBaseUrl: string;
  },
  mobileProducerSoftwareUrl: string;
}

const commonConfig = {
  rootRoute: '/dpl/mpsv',
  tvShowServicePath: '/dpl/tv-shows',
  productPath: '/dpl/pds/products',
  stockPath: '/dpl/pds/stock',
  saleCountPath: '/sales-count',
  productOnAirPath: '/dpl/tv-shows/productonair',
  assetsUrl: 'https://pic.hse24-dach.net/media/de/products',
  mobileProducerSoftwareUrl: 'https://www.hse.de/dpl/mps',
};

const development: Configuration = {
  ...commonConfig,
  env: Environment.Development,
  baseUrl: 'https://development.hse.de',
  cognito: {
    redirectBaseUrl: "https://development.hse.de/dpl/mpsv"
  },
  reportingService: 'https://reporting-service.checkout.hse24-development.cloud',
};

const staging: Configuration = {
  ...commonConfig,
  env: Environment.Staging,
  baseUrl: 'https://qas.hse.de',
  cognito: {
    redirectBaseUrl: "https://qas.hse.de/dpl/mpsv"
  },
  reportingService: 'https://reporting-service.checkout.hse24-staging.cloud',
};

const production: Configuration = {
  ...commonConfig,
  env: Environment.Production,
  baseUrl: 'https://www.hse.de',
  cognito: {
    redirectBaseUrl: "https://www.hse.de/dpl/mpsv"
  },
  reportingService: 'https://reporting-service.checkout.hse24.cloud',
};

const local: Configuration = {
  ...commonConfig,
  env: Environment.Local,
  baseUrl: 'https://development.hse.de',
  cognito: {
    redirectBaseUrl: "http://localhost:3000/dpl/mpsv"
  },
  reportingService: 'https://reporting-service.checkout.hse24-development.cloud',
  cognitoUserPoolDomain: 'https://mobile-producer-software-development.auth.eu-central-1.amazoncognito.com',
  cognitoClientId: '3h8mpkp791r5kfsa9v302t9jkk',
};

const getConfiguration = (): Configuration => {
  const configuration = prepareConfiguration();
  try {
    return { ...configuration, ...(window.envVars as EnvVars) };
  } catch (err) {
    console.error('could not load EnvVars', err);
    return configuration;
  }
}
const prepareConfiguration = (): Configuration => {
  const env = getEnvironment();

  switch (env) {
    case Environment.Local:
      return local;
    case Environment.Development:
      return development;
    case Environment.Staging:
      return staging;
    case Environment.Production:
      return production;
    default:
      throw Error(`Unknown environment: ${env}`);
  }
};

const Config = new Proxy<Configuration>(getConfiguration(), {
  get: (target: object, propertyKey: PropertyKey, receiver?: any) => {
    return Reflect.get(getConfiguration(), propertyKey, receiver);
  },
});

export default Config;
